<template>
	<div>
		<hero class="mb-6" title="International trade courses" subtitle="Choose from a range of courses focused on different areas of the global supply chain industry." />
		<b-container >
			<b-row>
				<b-col>
					<heading small>FEATURED COURSES</heading>
				</b-col>
			</b-row>
			<b-row class="mb-5">
				<featured-course :item="$store.state.courses[0]"></featured-course>
				<featured-course :item="$store.state.courses[1]"></featured-course>
			</b-row>
		</b-container>
		<sorted-courses>ALL INTERNATIONAL TRADE COURSES</sorted-courses>
	</div>
</template>

<script>
import FeaturedCourse from '@/components/ui/FeaturedCourse.vue'
import SortedCourses from "@/components/SortedCourses";
export default {
	name: 'InternationalTrade',
	components: {
		'featured-course': FeaturedCourse,
		'sorted-courses': SortedCourses,
	}
}
</script>

